
    //Stripe.setPublishableKey('pk_test_cb73C1az1VxJX5LXuxWigi19');
    Stripe.setPublishableKey('pk_live_OhQ12QvCeMdoIeKLBIi7EhTv');

    jQuery(function ($) {
        $(document).on("click", ".book .button-total", function () {
            if ($('#occupants').val() === '') {
                $('.detail-errors').html('<small class="error">Please Select a total number of occupants</small>');
                $('html, body').stop().animate({scrollTop: $('#booking-details').offset().top - 100}, 1500);
            } else {
                var first_name = $('input[name=first_name]').val();
                var last_name = $('input[name=last_name]').val();

                if ($('#signature').val() != first_name + ' ' + last_name) {
                    alert('The Rental Agreement is not signed or does not match your name. Please agree and sign the Rental Agreement before continuing.');
                    $('html, body').stop().animate({scrollTop: $('#terms').offset().top - 100}, 1500);
                } else {
                    $(this).html('Processing...');
                    $('#form--book').submit();
                }
            }
        });
        $('#form--book').submit(function (event) {
            var $form = $(this);
            var cc = $('#customer_card').val();

            if (typeof cc == 'undefined' || cc === 'new') {
                // Disable the submit button to prevent repeated clicks
                $form.find('button.button-total').prop('disabled', true);

                Stripe.card.createToken($form, stripeResponseHandlerBook);
            } else {
                $form.get(0).submit();
            }
            // Prevent the form from submitting with the default action
            return false;
        });

        $(document).on("click", ".payment .button-total", function () {
            console.log('test');
            $(this).html('Processing...');
            $('#form--payment').submit();
        });
        $('#form--payment').submit(function (event) {
            var $form = $(this);
            var cc = $('#customer_card').val();

            if (typeof cc == 'undefined' || cc === 'new') {
                // Disable the submit button to prevent repeated clicks
                $form.find('button.button-total').prop('disabled', true);

                Stripe.card.createToken($form, stripeResponseHandlerBook);
            } else {
                $form.get(0).submit();
            }
            // Prevent the form from submitting with the default action
            return false;
        });
    });

    function stripeResponseHandlerBook(status, response) {
        var $form = $('.form--stripe');

        if (response.error) {
            // Show the errors on the form
            $form.find('.payment-errors').html('<small class="error">' + response.error.message + '</small>');
            $form.find('button.button-total').prop('disabled', false);
            $('.button-total').html('Book Now');

            $('html, body').stop().animate({scrollTop: $('#stripe').offset().top - 100}, 1500);

        } else {
            // response contains id and card, which contains additional card details
            var token = response.id;
            // Insert the token into the form so it gets submitted to the server
            $form.append($('<input type="hidden" name="stripeToken" />').val(token));
            // and submit
            $form.get(0).submit();
        }
    };

    $(function () {

        // Create an Account
        $('.submit--account').on("click", function () {
            $(this).html('Processing...').attr('disabled', true);
            $('small.error').remove();

            var token = $("input[name=_token]").val(),
                first_name = $("#create input[name=register--first_name]").val(),
                last_name = $("#create input[name=register--last_name]").val(),
                email = $("#create input[name=register--email]").val(),
                phone = $("#create input[name=register--phone]").val(),
                dob_month = $("#create select[name=register--dob_month]").val(),
                dob_day = $("#create select[name=register--dob_day]").val(),
                dob_year = $("#create select[name=register--dob_year]").val(),
                password = $("#create input[name=register--password]").val(),
                password_confirmation = $("#create input[name=register--password_confirmation]").val();

            $.ajax({
                type: "POST",
                url: site + 'book-register',
                data: {
                    'name': first_name + ' ' + last_name,
                    'first_name': first_name,
                    'last_name': last_name,
                    'email': email,
                    'phone': phone,
                    'dob_month': dob_month,
                    'dob_day': dob_day,
                    'dob_year': dob_year,
                    'password': password,
                    'password_confirmation': password_confirmation,
                    '_token': token
                },
                dataType: "text",
                success: function (data) {
                    console.log(data);
                    var val = jQuery.parseJSON(data);
                    if (val.status === '1') {
                        success();
                        success_account();
                        $('input[name=first_name]').val(val.first_name);
                        $('input[name=last_name]').val(val.last_name);
                        $('input[name=email]').val(val.email);
                        $('input[name=phone]').val(val.phone);
                        $('.renter-name').val(val.first_name + ' ' + val.last_name);
                    }
                },
                error: function (xhr) {
                    $('.submit--account').html('Continue').attr('disabled', false);
                    var val = $.parseJSON(xhr.responseText);

                    $.each(val, function (key, value) {
                        $('[name=register--' + key + ']').after('<small class="error">' + value + '</small>');//.closest('div').addClass('error');
                    });
                }
            });

            return false;
        });

        // Login
        $(".submit--login").on("click", function () {
            $(this).html('Processing...');
            $('small.error').remove();

            var token = $("input[name=_token]").val(),
                email = $("input[name=login--email]").val(),
                password = $("input[name=login--password]").val();

            $.ajax({
                type: "POST",
                url: site + 'book-login',
                data: {
                    'email': email,
                    'password': password,
                    '_token': token
                },
                dataType: "text",
                success: function (data) {
                    var val = jQuery.parseJSON(data);
                    if (val.status === '1') {
                        success();
                        $('input[name=first_name]').val(val.first_name);
                        $('input[name=last_name]').val(val.last_name);
                        $('input[name=email]').val(val.email);
                        $('input[name=phone]').val(val.phone);
                        $('input[name=address]').val(val.address);
                        $('input[name=city]').val(val.city);
                        $('select[name=state]').val(val.state);
                        $('select[name=country]').val(val.country);
                        $('input[name=zip]').val(val.zip);
                        $('.renter-name').val(val.first_name + ' ' + val.last_name);
                        if (val.cards != '') {
                            $('.holder--payment-cards').fadeOut().html(val.cards).fadeIn();
                            $('select').select2({
                                minimumResultsForSearch: -1
                            });
                        } else {
                            success_account();
                        }
                    } else {
                        $('[name=login--email]').after('<small class="error">There is an error with your E-Mail Address or Password. Please try again</small>');
                        $('.submit--login').html('Login');
                    }
                }
            });
            return false;
        });

        $("#occupants").on("change", function () {
            var val = $(this).val();
            if (val != '') {
                $('.occupants').html(val);
            }
        });

        //$(document).on("click", ".close-sig", function () {
        $(".close-sig").off('click').on("click", function () {
            var first_name = $('input[name=first_name]').val();
            var last_name = $('input[name=last_name]').val();
            if ($('#signature').val() === first_name + ' ' + last_name) {
                $('label[for=policy-check]').before('<input type="checkbox" checked="checked" id="policy-check" name="policy" />');
                $.magnificPopup.close();
            } else {
                alert('Your signature needs to match ' + first_name + ' ' + last_name);
            }
        });

        // Deposit Filter
        $('.check-box-deposit').on('click', function () {
            var price = $(this).attr('data-price').replace('$', '');

            $('.input-deposit').prop('checked', false);
            $(this).closest('.input-deposit').prop('checked', true);
            var new_deposit_total = parseFloat(price).toFixed(2);

            //console.log(new_deposit_total);
            $('input[name=deposit]').val(new_deposit_total);

            adjust_price();
        });

        // Coverage Filter
        $('.check-box-coverage').on('click', function () {
            var price = $(this).attr('data-price').replace('$', '');
            var coverages = $('input[name=coverages]').val();
            $(this).toggleClass('active');
            if ($(this).hasClass('active')) {
                var new_coverages_total = (parseFloat(coverages) + parseFloat(price)).toFixed(2);
            } else {
                var new_coverages_total = (parseFloat(coverages) - parseFloat(price)).toFixed(2);
            }
            //console.log(new_coverages_total);
            $('input[name=coverages]').val(new_coverages_total);

            adjust_price();
        });

        // Coverage Filter
        $('.check-box-optional').off('click').on('click', function () {
            $(this).toggleClass('active');
            adjust_price();
        });

        // Payment Schedule
        $("#payment_schedule").on("change", function () {
            adjust_price();
        });

        // Select Payment
        $("#customer_card").on("change", function () {
            var val = $(this).val();

            if (val === 'new') {
                $('.holder--payment-options').removeClass('hide').fadeIn();
            } else {
                $('.holder--payment-options').fadeOut();
            }
        });

    });

    function adjust_price() {
        var token = $("input[name=_token]").val(),
            schedule_id = $("#payment_schedule").val(),
            coverages = $('input[name=coverages]').val(),
            deposit = $('input[name=deposit]').val(),
            deposit_type = $('input[name=deposit_type]').val(),
            property_id = $('input[name=property_id]').val(),
            start = $('input[name=start]').val(),
            end = $('input[name=end]').val(),
            rate_total = $('input[name=rate_total]').val(),
            rate_num = $('input[name=rate_num]').val();

        var optional = [];
        $(".check-box-optional.active").each(function(i) {
            var id = $(this).attr('for');
            optional.push($('#'+id).val());
        });

        console.log(optional);
        if (deposit === '0') {
            deposit = $('input[name=deposit_total]').val();
        }

        $.ajax({
            type: "POST",
            url: site + 'due-now',
            data: {
                'schedule_id': schedule_id,
                'coverages': coverages,
                'deposit': deposit,
                'deposit_type': deposit_type,
                'property_id': property_id,
                'start': start,
                'end': end,
                'rate_total': rate_total,
                'rate_num': rate_num,
                'optional': optional,
                '_token': token
            },
            dataType: "text",
            success: function (data) {
                var val = jQuery.parseJSON(data);
                $('.due-now span').html('$' + val.total_format);
                $('input[name=today_total]').val(val.total);
                $('.sub-total h2').html('$' + val.total_format);
                $('.holder--fees').fadeOut().promise().done(function () {
                    $('.holder--fees').html(val.holder_fees).fadeIn();
                });
                if (val.grand_total === undefined) {
                    $('.sub-total h2').html('$' + val.total_format);
                    $('.holder--duenow').html('');
                } else {
                    $('.sub-total h2').html('$' + val.grand_total);
                    $('.holder--duenow').html('<h4>Due Now</h4><h2>$' + val.total_format + '</h2>');
                }
                if (val.schedule === undefined) {
                    $('.schedule').html('');
                } else {
                    $('.schedule').html(val.schedule);
                }
            }
        });
    }

    function success() {
        $('.holder--form #tabs').fadeOut().promise().done(function () {
            $('.holder--form #tabs').html('<div class="success">Success!</div>').fadeIn();
        });
        //$('label[for=policy-check]').before('<input type="checkbox" id="policy-check" name="policy" />');
        //$('.check-policy .label--checkout').html('<a href="#rental-agreement-popup" class="open-popup-link">Rental Agreement</a>');
        $('.step--details').removeClass('active');
        $('.step--payment').addClass('active');
        $('.step--terms').addClass('active');
        $('.step--payment input, .step--payment select').attr('disabled', false);
    }

    function success_account() {
        $('.holder--payment-options').removeClass('hide');
    }