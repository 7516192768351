$(function () {

// Inquiry Form
    $(document).on("click", ".action--inquiry-form", function () {
        $(this).html('Processing...');
        $('small.error').remove();
        console.log('test');
        var token = $("input[name=_token]").val(),
            property_id = $("input[name=inquiry--property_id]").val(),
            first_name = $("input[name=inquiry--first_name]").val(),
            last_name = $("input[name=inquiry--last_name]").val(),
            email = $("input[name=inquiry--email]").val(),
            phone = $("input[name=inquiry--phone]").val(),
            adults = $("input[name=inquiry--adults]").val(),
            children = $("input[name=inquiry--children]").val(),
            flexible = $("input[name=inquiry--flexible]").val(),
            start = $("input[name=inquiry--start]").val(),
            end = $("input[name=inquiry--end]").val(),
            content = $("textarea[name=inquiry--message]").val();

        $.ajax({
            type: "POST",
            url: site + 'inquiry-form',
            data: {
                'property_id': property_id,
                'first_name': first_name,
                'last_name': last_name,
                'email': email,
                'phone': phone,
                'adults': adults,
                'children': children,
                'flexible': flexible,
                'start': start,
                'end': end,
                'content': content,
                '_token': token
            },
            dataType: "text",
            success: function (data) {
                var val = jQuery.parseJSON(data);
                if (val.status === '1') {
                    $('#contact-us-popup .payment').fadeOut().promise().done(function () {
                        $('#contact-us-popup .payment').html('<h2 class="success">Success! We will be in contact with you shortly.</h2>').fadeIn();
                    });
                }
            },
            error: function (xhr) {
                $('.action--inquiry-form').html('Submit');
                var val = $.parseJSON(xhr.responseText);

                $.each(val, function (key, value) {
                    $('[name=inquiry--' + key + ']').after('<small class="error">' + value + '</small>');
                });
            }
        });
    });

});