// Add Email
$(".item--add").on( "click", function() {
    var num = Math.floor((Math.random() * 1000) + 2);

    var email = '<div class="item--email"><input type="email" class="split--email" name="user['+num+'][email]" placeholder="Email" /> $ <input type="text" class="split--amount" name="user['+num+'][amount]" placeholder="Amount" /> <a class="item--add"><span class="icon-outline-add-3"></span></a> <a class="item--minus"><span class="icon-outline-minus-3"></span></a></div>';
    $('.holder--emails').append(email);

    //split_total();
});

// Remove Email
$(".item--minus").on( "click", function() {
    $(this).closest('.item--email').remove();

    //split_total();
});

function split_total()
{
    var total = $('#total').attr('data-total'),
        count = $('.split-email').length,
        message = $('textarea[name=message]').attr('data-message');

    var new_total = parseFloat(total / count);
    $('textarea[name=message]').html(message + new_total.toFixed(2));
}