
    // Sleeps Popover
    $('.popover-markup>.trigger').popover({
        html: true,
        placement: 'top',
        trigger: 'manual',
        content: function () {
            return $.ajax({
                url: site + 'sleep',
                dataType: 'html',
                async: false
            }).responseText;
        }
    }).click(function (e) {
        $(this).popover('toggle');
    });

    $('body').on('click', function (e) {
        $('.popover-markup>.trigger').each(function () {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
                $('#sleeps').removeClass('sleeps-click');
            }
        });
    });

    jQuery(document).ready(function () {

        $(function () {

            availableTags = [];
            $(".markets").each(function () {
                var name = $(this).val();
                availableTags.push(name);
            });

            var previousValue = "";
            $("#search_auto").autocomplete({
                source: availableTags,
                appendTo: '.quick-search_dest'
            }).keyup(function () {
                var isValid = false;
                for (i in availableTags) {
                    if (availableTags[i].toLowerCase().match(this.value.toLowerCase())) {
                        isValid = true;
                    }
                }
                if (!isValid) {
                    this.value = previousValue
                } else {
                    previousValue = this.value;
                }
            });
        });
    });
    $('#sleeps').click(function (e) {
        e.preventDefault();
        $('#sleeps').toggleClass('sleeps-click');
    });

   $('.idatepicker input').datepicker({
        autoclose: true,
        startDate: 'd',
        format: "mm/dd/yyyy"
    });

    $(document).ready(function () {
        $('.home .quick-search').makeFixed({
            onFixed: function (el) {
                //$(".single .quick-search").addClass("fixed")
            },
            onUnFixed: function (el) {
                //$(".single .quick-search").removeClass("fixed")
            }
        });

        if ($(window).width() < 767) {
        } else {
            $('#filter-menu').makeFixed
            ({
                onFixed: function (el) {
                    if ($(el).attr('id') == 'fiter-menu') {
                        $(el).children().css
                        ({
                            top: '200px'
                        });
                    }
                },
                onUnFixed: function (el) {
                    if ($(el).attr('id') == 'fiter-menu') {
                        $(el).children().css
                        ({
                            top: '380px !important'
                        });
                    }
                }
            });
        }

        $(".filter-type").click(function () {
            $(this).toggleClass("fade-filter");
            $(this).toggleClass("active");
            if ($(this).children('i').hasClass('icon-outline-delete-3')) {
                $(this).children('i').removeClass('icon-outline-delete-3').addClass('icon-outline-add-3');
            } else {
                $(this).children('i').removeClass('icon-outline-add-3').addClass('icon-outline-delete-3');
            }
        });



    });

    $('.quick-search_form').each(function() {
        var default_value = this.value;
        $(this).focus(function(){
            if(this.value == default_value) {
                this.value = '';
            }
        });
        $(this).blur(function(){
            if(this.value == '') {
                this.value = default_value;
            }
        });
    });

