
    // Contact Form
    $(".action--contact-form").on("click", function () {
        $(this).html('Processing...');
        $('small.error').remove();
        console.log('test');
        var token = $("input[name=_token]").val(),
            topic = $("select[name=contact--topic]").val(),
            name = $("input[name=contact--name]").val(),
            email = $("input[name=contact--email]").val(),
            phone = $("input[name=contact--phone]").val(),
            message = $("textarea[name=contact--message]").val();

        $.ajax({
            type: "POST",
            url: site + 'contact-form',
            data: {
                'topic': topic,
                'name': name,
                'email': email,
                'phone': phone,
                'message': message,
                '_token': token
            },
            dataType: "text",
            success: function (data) {
                var val = jQuery.parseJSON(data);
                if (val.status === '1') {
                    $('.payment').fadeOut().promise().done(function () {
                        $('.payment').html('<h2 class="success">Success! We will be in contact with you shortly.</h2>').fadeIn();
                    });
                }
            },
            error: function (xhr) {
                $('.action--contact-form').html('Submit');
                var val = $.parseJSON(xhr.responseText);

                $.each(val, function (key, value) {
                    $('[name=contact--' + key + ']').after('<small class="error">' + value + '</small>');
                });
            }
        });
    });

// Contact Form
    $(".action--property-inquiry").on("click", function () {
        $(this).html('Processing...');
        $('small.error').remove();

        var token = $("input[name=_token]").val(),
            address = $("input[name=list--address]").val(),
            city = $("input[name=list--city]").val(),
            state = $("select[name=list--state]").val(),
            zip = $("input[name=list--zip]").val(),
            message = $("textarea[name=list--message]").val();

        $.ajax({
            type: "POST",
            url: site + 'property-inquiry',
            data: {
                'address': address,
                'city': city,
                'state': state,
                'zip': zip,
                'message': message,
                '_token': token
            },
            dataType: "text",
            success: function (data) {
                var val = jQuery.parseJSON(data);
                if (val.status === '1') {
                    $('.inquiry').fadeOut().promise().done(function () {
                        $('.inquiry').html('<h2 class="title">Success! We will be in contact with you shortly.</h2>').fadeIn();
                    });
                }
            },
            error: function (xhr) {
                $('.action--property-inquiry').html('Submit');
                var val = $.parseJSON(xhr.responseText);

                $.each(val, function (key, value) {
                    $('[name=list--' + key + ']').after('<small class="error">' + value + '</small>');
                });
            }
        });
    });

