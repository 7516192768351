
    function collision($div1, $div2) {
        var x1 = $div1.offset().left;
        var w1 = 40;
        var r1 = x1 + w1;
        var x2 = $div2.offset().left;
        var w2 = 40;
        var r2 = x2 + w2;

        if (r1 < x2 || x1 > r2) return false;
        return true;

    }

    // // slider call
    $(document).ready(function() {

        var listing_max = $('input[name=listing-max]').val();

        $('#listing-slider').slider({
            range: true,
            min: 0,
            max: listing_max,
            step: 50,
            values: [0, listing_max],
            slide: function (event, ui) {

                $('.ui-slider-handle:eq(0) .price-range-min').html('$' + ui.values[0]);
                $('.ui-slider-handle:eq(1) .price-range-max').html('$' + ui.values[1]);
                $('.price-range-both').html('<i>$' + ui.values[0] + ' - </i>$' + ui.values[1]);
                $('#price_range').val(ui.values[0] + '*' + ui.values[1]);
                //

                if (ui.values[0] == ui.values[1]) {
                    $('.price-range-both i').css('display', 'none');
                } else {
                    $('.price-range-both i').css('display', 'inline');
                }

                //

                if (collision($('.price-range-min'), $('.price-range-max')) == true) {
                    $('.price-range-min, .price-range-max').css('opacity', '0');
                    $('.price-range-both').css('display', 'block');
                } else {
                    $('.price-range-min, .price-range-max').css('opacity', '1');
                    $('.price-range-both').css('display', 'none');
                }

            }
        });

        $('.ui-slider-range').append('<span class="price-range-both value"><i>$' + $('#listing-slider').slider('values', 0) + ' - </i>' + $('#listing-slider').slider('values', 1) + '</span>');

        $('.ui-slider-handle:eq(0)').append('<span class="price-range-min value">$' + $('#listing-slider').slider('values', 0) + '</span>');

        $('.ui-slider-handle:eq(1)').append('<span class="price-range-max value">$' + $('#listing-slider').slider('values', 1) + '</span>');

        /*$(document).on( "change", "#sleeps", function() {
         listingsFilters();
         });*/
        $('.quick-search_form').each(function () {
            var default_value = this.value;
            $(this).focus(function () {
                if (this.value == default_value) {
                    this.value = '';
                }
            });
            $(this).blur(function () {
                if (this.value == '') {
                    this.value = default_value;
                }
            });
        });
        $("#apply-selected").on("click", function () {
            listingsFilters();
            $(".listing-apply-filters").removeClass("listing-apply-filters-click");
            $("#filter-menu").fadeToggle();
        });
        $(".listing-apply-filters").on("click", function () {
            if ($(this).hasClass("listing-apply-filters-click")) {
                listingsFilters();
            }
            $(".listing-apply-filters").toggleClass("listing-apply-filters-click");
            $(".quick-search_map").removeClass('active');
            $(".quick-search_map h3").html('Map');
            $("#map-menu").fadeOut().promise().done(function () {
                $("#filter-menu").fadeToggle();
            });
        });

        $(".quick-search_map").on("click", function () {
            if ($(this).hasClass("active")) {
                $(".quick-search_map h3").html('Map');
            } else {
                $(".quick-search_map h3").html('Return');
            }
            $(this).toggleClass("active");
            $(".listing-apply-filters").removeClass("listing-apply-filters-click");
            $("#filter-menu").fadeOut().promise().done(function () {
                $("#map-menu").fadeToggle();
            });
            listingsMap();
        });
        $(".maps__close").on("click", function () {
            $("#map-menu").fadeOut();
            $(".quick-search_map").removeClass('active');
            $(".quick-search_map h3").html('Map');
        });

        $(".quick-search_search").on("click", function () {
            listingsFilters();
        });
        $("#sleeps_select, #bedrooms, #sort, #duration, #booking").on("change", function () {
            listingsFilters();
        });

        $(".filter-section .filter-type").on("click", function () {
            if ($(this).hasClass('active')) {
                console.log('active');
                $(this).removeClass('fade-filter').addClass('active');
            } else {
                $(this).removeClass('active').addClass('fade-filter');
            }
            if ($(this).children('i').hasClass('icon-outline-delete-3')) {
                $(this).children('i').removeClass('icon-outline-delete-3').addClass('icon-outline-add-3');
            } else {
                $(this).children('i').removeClass('icon-outline-add-3').addClass('icon-outline-delete-3');
            }
        });

        $(".button-listing-toggle").on("click", function () {
            var name = $(this).attr('data-name');
            if(name == 'entire-stay'){
                $(this).addClass('entire-stay-click');
                if($('#start').val() != '' && $('#end').val() != ''){
                    updateRange(name);
                } else {
                    alert('Please Select an Arrive and Depart date.');
                }
            } else {
                $(this).addClass('per-night-click');
                updateRange(name);
            }
        });

    });

    function updateRange(name){
        $('.button-listing-toggle').removeClass('active');
        $('.button-listing-toggle[data-name='+name+']').addClass('active');

        listingsFilters();
    }

    function listingsFilters() {

        if ($(".quick-search_map").hasClass('active')) {
            listingsMap();
        }

        $('.holder--listings').fadeOut().promise().done(function () {
            $('.holder--listings').html('<p class="text-center loader-pad"><img src="' + site + 'img/loader_white.gif" /></p>').fadeIn();
        });
///img/loader-128x/Preloader_2.gif

        if($('.button-listing-toggle.active').hasClass('entire-stay-click')){
            var entire_stay_click = 1;
        } else {
            var entire_stay_click = 0;
        }

        var search = $('#search').val(),
            price_range = $('#price_range').val(),
            sleeps = $('#sleeps_select').val(),
            bedrooms = $('#bedrooms').val(),
            booking = $('#booking').val(),
            duration = $('#duration').val(),
            sort = $('#sort').val(),
            start = $('#start').val(),
            end = $('#end').val(),
            range_type = $('.button-listing-toggle.active').attr('data-name'),
            token = $("input[name=_token]").val();

        var filters = [];
        $('.filter-type.active').each(function (i) {
            filters.push($(this).attr('data-id'));
        });

        $.ajax({
            type: "POST",
            url: site + 'listings-filter',
            data: {
                'search': search,
                'price_range': price_range,
                'sleeps': sleeps,
                'bedrooms': bedrooms,
                'booking': booking,
                'duration': duration,
                'sort': sort,
                'filters': filters,
                'start': start,
                'end': end,
                'range_type': range_type,
                'entire_stay_click': entire_stay_click,
                '_token': token
            },
            dataType: "text",
            success: function (data) {
                console.log(data);
                var val = jQuery.parseJSON(data);
                $('.holder--listings').fadeOut().promise().done(function () {
                    $('.holder--listings').html(val.html).fadeIn();
                    if(val.listing_max != null) {

                        if($(".button-listing-toggle").hasClass('entire-stay-click') || $(".button-listing-toggle").hasClass('per-night-click')){
                            $("#listing-slider").slider('option',{min: 0, max: val.listing_max});
                            $("#listing-slider").slider('values',0,0); // sets first handle (index 0) to 50
                            $("#listing-slider").slider('values',1,val.listing_max);
                            $(".price-range-min").html('$0');
                            $(".price-range-max").html('$'+val.listing_max);
                            $("#price_range").val("0*"+val.listing_max);
                        }

                        $(".button-listing-toggle").removeClass('entire-stay-click').removeClass('per-night-click');

                        //$('#listing-slider').slider("values", 1, val.listing_max).slider("option", "max", val.listing_max);
                        //$('#listing-slider').slider("option", "value", 100);
                    }
                });
            }
        });

    }

    function listingsMap() {

        /*$('.holder--maps').fadeOut().promise().done( function() {
         $('.holder--maps').html('<p class="text-center loader-pad"><img src="'+site+'img/loader_white.gif" /></p>').fadeIn();
         });*/

        if($('.button-listing-toggle.active').hasClass('entire-stay-click')){
            var entire_stay_click = 1;
        } else {
            var entire_stay_click = 0;
        }

        var search = $('#search').val(),
            price_range = $('#price_range').val(),
            sleeps = $('#sleeps_select').val(),
            bedrooms = $('#bedrooms').val(),
            booking = $('#booking').val(),
            sort = $('#sort').val(),
            start = $('#start').val(),
            end = $('#end').val(),
            range_type = $('.button-listing-toggle.active').attr('data-name'),
            token = $("input[name=_token]").val();

        var filters = [];
        $('.filter-type.active').each(function (i) {
            filters.push($(this).attr('data-id'));
        });

        $.ajax({
            type: "POST",
            url: site + 'listings-map',
            data: {
                'search': search,
                'price_range': price_range,
                'sleeps': sleeps,
                'bedrooms': bedrooms,
                'booking': booking,
                'sort': sort,
                'filters': filters,
                'start': start,
                'end': end,
                'range_type': range_type,
                'entire_stay_click': entire_stay_click,
                '_token': token
            },
            dataType: "html",
            success: function (data) {
                if (data == '') {
                    $('#listing_map').html('<p class="text-center">Sorry, no listings found.</p>');
                } else {
                    $('.holder--maps').html(data);
                }
            }
        });

    }

    $(document).ready(function () {
        if ($(window).width() < 767) {
        }
        else {
            $('#listings-filter').makeFixed
            ({
                onFixed: function (el) {
                    if ($(el).attr('id') == 'listings-fiter') {
                        $(el).children().css
                        ({
                            // margin: '10px auto 0 auto'
                        });
                    }
                    $(".filter-container").addClass("filter-fixed-spacing")
                },
                onUnFixed: function (el) {
                    if ($(el).attr('id') == 'listings-fiter') {
                        $(el).children().css
                        ({
                            // margin: '10px auto 0 auto'
                        });
                    }
                    $(".filter-container").removeClass("filter-fixed-spacing")
                }
            });
        }

        if ($(window).width() < 767) {
        } else {
            $('#map-menu').makeFixed
            ({
                onFixed: function (el) {
                    if ($(el).attr('id') == 'map-menu') {
                        $(el).children().css
                        ({
                            //top: '200px'
                        });
                    }
                },
                onUnFixed: function (el) {
                    if ($(el).attr('id') == 'map-menu') {
                        $(el).children().css
                        ({
                            //top: '380px !important'
                        });
                    }
                }
            });
        }

    });

