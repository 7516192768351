var datapickerinput = $('.quick-search_check-in .input-daterange').datepicker({
    autoclose: false,
    startDate: 'd',
    format: "mm/dd/yyyy",
}).on('changeDate', function(e){
    var start = $('input[name=start]').val();
    var end = $('input[name=end]').val();
    $('.date-check').removeClass('checked');
    if(start == end){
        $('input[name=start]').datepicker('hide');
        $('input[name=end]').datepicker('show');
    } else {
        $('input[name=end]').datepicker('hide');
    }
});

$(window).scroll(function () {
    if($('html').hasClass('no-touch')) {
        $('input[name=start]').datepicker('hide');
        $('input[name=end]').datepicker('hide');
        $('input[name=start]').blur();
        $('input[name=end]').blur();
    }
});

$('header').on('click', '.menu-button', function(event){
    $('#side-menu').sidr({
        side: 'right'
    });
});
$('.home input[name=start]').on('click', function() {
    $('.date-open').val('start');
});
$('.home input[name=end]').on('click', function() {
    $('.date-open').val('end');
});
$('.date-check').on('click', function() {
    $(this).toggleClass('checked');
    var open = $('.date-open').val();

    if($(this).hasClass('checked')) {
        $('.home input[name=' + open + ']').val('');
    }
});