
    // Help
    $(".help-aside-nav").on("click", function () {
        var id = $(this).attr('data-id');
        var cat = $(this).attr('data-cat');
        var token = $("input[name=_token]").val();

        $('.content--' + cat + ' .help-accordion').fadeOut().promise().done(function () {
            $('.content--' + cat + ' .help-accordion').html('<p class="text-center loader-pad"><img src="' + site + 'img/loader_white.gif" /></p>').fadeIn();
        });

        $('.content--' + cat + ' .help-aside-nav').removeClass('active');
        $(this).addClass('active');

        if (cat != 'start') {
            $.ajax({
                type: "POST",
                url: site + 'help-content',
                data: {
                    'id': id,
                    'cat': cat,
                    '_token': token
                },
                dataType: "text",
                success: function (data) {
                    $('.content--' + cat + ' .help-accordion').fadeOut().promise().done(function () {
                        $('.content--' + cat + ' .help-accordion').html(data).fadeIn();
                    });
                }
            });
        }
    });

    // Help Search
    $(".search-button").on("click", function () {
        var search = $('input[name=search]').val();
        var token = $("input[name=_token]").val();

        if (search != '') {

            $('.content--all .help-accordion').fadeOut().promise().done(function () {
                $('.content--all .help-accordion').html('<h1 class="subtitle">Searching...</h1>').fadeIn();
                $('[data-id=all]').click();
            });

            $('.content--all .help-aside-nav').removeClass('active');

            $.ajax({
                type: "POST",
                url: site + 'help-search',
                data: {
                    'search': search,
                    '_token': token
                },
                dataType: "text",
                success: function (data) {
                    $('.content--all .help-accordion').fadeOut().promise().done(function () {
                        $('.content--all .help-accordion').html(data).fadeIn();
                    });
                }
            });
        }
    });

    // Help Contact
    $(".help--contact").on("click", function () {
        $('[data-id=contact]').click();
    });

    $('.opened').children('.accordBody').show();
    $(document).on("click", ".accordHead", function () {
        if (!$(this).parent('.accordCnt').hasClass('opened')) {
            $('.accordCnt').removeClass('opened');
            $('.accordBody').slideUp();
            $(this).parent('.accordCnt').addClass('opened');
            $(this).next('.accordBody').slideDown();
        }
    });


